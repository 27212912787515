import '@babel/polyfill';

import { AuthService }               from 'aurelia-authentication';
import { PLATFORM }                  from 'aurelia-pal';
import { AureliaAuthConfig }         from 'resources/configs/aurelia-auth-config';
import { AureliaI18NConfig }         from 'resources/configs/aurelia-i18n-config';
import { AureliaDialogConfig }       from 'resources/configs/aurelia-dialog-config';
import { ValidationMessageProvider } from 'aurelia-validation';
import { I18N }                      from 'aurelia-i18n';
import environment                   from 'environment';
import * as Bluebird                 from 'bluebird';

import 'assets/css/icons/icomoon/styles.min.css';
import 'assets/css/bootstrap.min.css';
import 'assets/css/bootstrap_limitless.min.css';
import 'assets/css/layout.min.css';
import 'assets/css/components.min.css';
import 'assets/css/colors.min.css';
import 'assets/css/material-design-spinner.min.css';
import 'assets/css/custom.min.css';
import 'resources/support/utilities';

// remove out if you don't want a Promise polyfill (remove also from webpack.config.js)
Bluebird.config({warnings: {wForgottenReturn: false}});

export function configure(aurelia) {
    aurelia.use
        .standardConfiguration()
        .developmentLogging(environment.debug ? 'debug' : 'warn')
        .plugin(PLATFORM.moduleName('jquery'))
        .plugin(PLATFORM.moduleName('bootstrap'))
        .plugin(PLATFORM.moduleName('aurelia-animator-css'))
        .plugin(PLATFORM.moduleName('aurelia-authentication'), config => config.configure(AureliaAuthConfig))
        .plugin(PLATFORM.moduleName('aurelia-i18n'), config => AureliaI18NConfig.configure(aurelia, config))
        .plugin(PLATFORM.moduleName('aurelia-dialog'), config => AureliaDialogConfig.configure(config))
        .plugin(PLATFORM.moduleName('aurelia-validation'))
        .plugin(PLATFORM.moduleName('aurelia-ui-virtualization'))
        .feature(PLATFORM.moduleName('resources/attributes/index'))
        .feature(PLATFORM.moduleName('resources/elements/aurelia-form/index'))
        .feature(PLATFORM.moduleName('resources/elements/form-elements/index'))
        .feature(PLATFORM.moduleName('resources/elements/html-elements/index'))
        .feature(PLATFORM.moduleName('resources/value-converters/index'))
        .preTask(configAureliaValidation)
        .preTask(registerAdditionalPlugins)
        .preTask(handlePopovers);

    aurelia.start().then(instance => aurelia.setRoot(applicableRoot(instance)));
}

/**
 * Registers additional plugins
 *
 * @param frameworkConfiguration
 *
 * @returns {*}
 */
function registerAdditionalPlugins(frameworkConfiguration) {
    if (environment.testing) {
        frameworkConfiguration.aurelia.use.plugin(PLATFORM.moduleName('aurelia-testing'));
    }

    return frameworkConfiguration;
}

/**
 * Configures aurelia validation
 *
 * @param aurelia
 */
function configAureliaValidation(aurelia) {
    ValidationMessageProvider.prototype.getMessage = function (key) {
        const i18n        = aurelia.container.get(I18N);
        const translation = i18n.tr(`validation.${key}`)
            .replace(':attribute', '${$displayName}')
            .replace(':min', '${$config.min}')
            .replace(':max', '${$config.max}');
        return this.parser.parse(translation);
    };

    ValidationMessageProvider.prototype.getDisplayName = function (propertyName, displayName) {
        let property = displayName || propertyName;

        const i18n = aurelia.container.get(I18N);

        return i18n.tr(property);
    };
}

/**
 * Returns applicable root
 *
 * @param frameworkConfiguration
 *
 * @returns {string}
 */
function applicableRoot(frameworkConfiguration) {
    let auth = frameworkConfiguration.container.get(AuthService);

    return auth.isAuthenticated() ? PLATFORM.moduleName('app') : PLATFORM.moduleName('app-offline');
}

/**
 * Closes popovers when clicking outside
 *
 * TODO: think of a better place for this...
 */
function handlePopovers() {
    $('body').on('click', function (e) {
        $('[data-toggle="popover"]').each(function () {
            //the 'is' for buttons that trigger popups
            //the 'has' for icons within a button that triggers a popup
            if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                $(this).popover('hide');
            }
        });
    });
}
